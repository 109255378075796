import { defineStore } from "pinia";

import User, { Profile } from "./security/SecuyrityEntities";

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useGlobalStore = defineStore("globalStore", {
    state: (): GlobalStoreVO => ({ loading: false, loggedUser: new UserWithProfile() }),
    actions: {
        setLoggedUser(newLoggedUser: User) {
            this.loggedUser = newLoggedUser;
        },
        setLoading(loading: boolean) {
            // console.log("setLoading", loading);
            this.loading = loading;
        },
    },
});

export interface GlobalStoreVO {
    loggedUser?: UserWithProfile;
    loading: boolean;
}

export class UserWithProfile extends User {
    constructor(id?: number, username?: string, public perfis?: Profile[]) {
        super(id, username);
    }
}
