import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/pages/home/Home.vue";
import SecurityService from "../domain/security/SecurityService";

Vue.use(VueRouter);

export enum Routes {
    MUSICS = "/media/musics",
    MUSIC = "/media/music",
    MUSIC_TEMPLATES = "/media/musicTemplates",
    MUSIC_TEMPLATE = "/media/musicTemplate",
    MUSIC_TRAININGS = "/musicTrainings",
    MUSIC_TRAINING = "/musicTraining",
    EXERCISES = "/exercises",
    EXERCISE = "/exercise",
    USER = "/user",
    USERS = "/users",
    MUSCLES = "/muscles",
    MUSCLE = "/muscle",
    USER_CHANGE_PASS = "/user/change/pass",
    EXPORTER = "/exporter",
    EXERCISE_TYPES = "/exerciseTypes",
    EXERCISE_TYPE = "/exerciseType",
    GYMS = "/gyms",
    GYM = "/gym",
    STUDENTS = "/students",
    STUDENT = "/student",
}

const menu: any[] = [
    { path: "*", component: Home, meta: { titulo: "Painel", menu: false } },
    {
        path: "/",
        name: "painel",
        component: Home,
        meta: { titulo: "Painel", menu: false },
    },
    {
        path: Routes.MUSICS,
        name: "musics",
        component: () => import("../components/pages/media/MusicsPage.vue"),
        meta: { titulo: "Music", menu: true, icon: "fas fa-music" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.MUSIC + "/:id",
        name: "music",
        component: () => import("../components/pages/media/MusicPage.vue"),
        meta: { titulo: "Music", menu: false, icon: "fas fa-music" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.MUSIC_TEMPLATES,
        name: "musicTemplates",
        component: () => import("../components/pages/media/MusicalTemplatesPage.vue"),
        meta: { titulo: "Music Template", menu: true, icon: "fas fa-list-ol" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.MUSIC_TEMPLATE + "/:id",
        name: "musicTemplate",
        component: () => import("../components/pages/media/MusicalTemplatePage.vue"),
        meta: { titulo: "Music Template", menu: false, icon: "fas fa-list-ol" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.MUSIC_TRAININGS,
        name: "musicTrainings",
        component: () => import("../components/pages/exercise/MusicalTrainingsPage.vue"),
        meta: { titulo: "Musical Training", menu: true, icon: "fas fa-volume-up" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.MUSIC_TRAINING + "/:id",
        name: "musicTraining",
        component: () => import("../components/pages/exercise/MusicalTrainingPage.vue"),
        meta: { titulo: "Music Training", menu: false, icon: "fas fa-volume-up" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.EXPORTER,
        name: "exporter",
        component: () => import("../components/pages/exercise/ExporterPage.vue"),
        meta: { titulo: "Exporter", menu: true, icon: "fas fa-share-square" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.EXERCISES,
        name: "exercises",
        component: () => import("../components/pages/exercise/ExercisesPage.vue"),
        meta: { titulo: "Exercises", menu: true, icon: "fas fa-running" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.EXERCISE + "/:id",
        name: "exercise",
        component: () => import("../components/pages/exercise/ExercisePage.vue"),
        meta: { titulo: "Exercises", menu: false, icon: "fas fa-running" },
        perfisPermitidos: [SecurityService.PERFIL_ADM, SecurityService.PERFIL_INSTRUTOR],
    },
    {
        path: Routes.GYM + "/:id",
        name: "gym",
        component: () => import("../components/pages/training/GymPage.vue"),
        meta: { titulo: "Gym", menu: false, icon: "fas fa-warehouse" },
        perfisPermitidos: [SecurityService.PERFIL_ADM],
    },
    {
        path: Routes.GYMS,
        name: "gyms",
        component: () => import("../components/pages/training/GymsPage.vue"),
        meta: { titulo: "Gym", menu: true, icon: "fas fa-warehouse" },
        perfisPermitidos: [SecurityService.PERFIL_ADM],
    },
    {
        path: Routes.STUDENT + "/:id",
        name: "student",
        component: () => import("../components/pages/training/StudentPage.vue"),
        meta: { titulo: "Student", menu: false, icon: "fas fa-running" },
        perfisPermitidos: [SecurityService.PERFIL_ADM],
    },
    {
        path: Routes.STUDENTS,
        name: "students",
        component: () => import("../components/pages/training/StudentsPage.vue"),
        meta: { titulo: "Student", menu: true, icon: "fas fa-running" },
        perfisPermitidos: [SecurityService.PERFIL_ADM],
    },

    {
        path: Routes.USERS,
        name: "users",
        component: () => import("../components/pages/users/UsersPage.vue"),
        meta: { titulo: "Users", menu: true, icon: "fas fa-user" },
        perfisPermitidos: [SecurityService.PERFIL_ADM],
    },
    {
        path: Routes.USER + "/:id",
        name: "user",
        component: () => import("../components/pages/users/UserPage.vue"),
        meta: { titulo: "User", menu: false, icon: "fas fa-user" },
        perfisPermitidos: [SecurityService.PERFIL_ADM],
    },
    {
        path: Routes.USER_CHANGE_PASS,
        name: "user_change_pass",
        component: () => import("../components/pages/users/ChangePasswordPage.vue"),
        meta: { titulo: "Change my password", menu: false, icon: "fas fa-key" },
        perfisPermitidos: [SecurityService.PERFIL_ADM],
    },
];

const routes: any[] = [];
menu.forEach((m) => {
    if (m.path) {
        routes.push(m);
    } else if (m.itens) {
        m.itens.forEach((m2: any) => {
            if (m2.path) routes.push(m2);
        });
    }
});

const router = new VueRouter({
    routes,
});

export { router, routes, menu };
