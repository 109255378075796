export default class Constantes {
    static get DATE_FORMAT() {
        return "YYYY-MM-DD h:mm:ss";
    }

    static get JWT_COOIKE() {
        return "jwt";
    }

    static get CRUD_DELETAR() {
        return "deletando";
    }
}
