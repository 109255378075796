
import { Component } from "vue-property-decorator";
import BaseComponent from "./components/BaseComponent";
import Menu from "./components/shared/menu/Menu.vue";
import { routes } from "./configs/router";

import { AxiosError, AxiosResponse } from "axios";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import Login from "./components/pages/login/Login.vue";
import Box from "./components/shared/painel/Box.vue";
import AxiosHttp, { GoModeException } from "./domain/AxiosHttp";
import SecurityService from "./domain/security/SecurityService";
import SystemService from "./domain/sistema/SystemService";

@Component({ components: { menus: Menu, Login, Box } })
export default class AppPage extends BaseComponent {
    routes = routes;
    versao = "?.?.?";
    logged = false;
    segService: SecurityService = null;
    systemService = new SystemService();
    get loading(): boolean {
        return this.globalStore.loading;
    }

    created() {
        this.segService = new SecurityService();
        AxiosHttp.httpInstance.interceptors.response.use(
            (response: AxiosResponse) => {
                if (response.status >= 400) console.error("ERRO NO APP", this);

                return response;
            },
            (error: AxiosError) => {
                let response = error.response as AxiosResponse<GoModeException, any>;
                console.log(error);
                if (response) {
                    if (response.status == 400) {
                        this.showErrorModal(response.data.message);
                        throw response.data;
                        // return Promise.reject(error);
                    } else if (response.status == 500) {
                        this.showErrorModal(
                            "Unexpected error, please contact GoMode admnistration: " + response
                                ? response.data.message || JSON.stringify(response.data)
                                : "no response"
                        );
                        throw response ? response.data : error;
                        // return Promise.reject(error);
                    } else if (response.status == 401) {
                        this.showErrorModal(response.data.message);
                        this.logged = false;
                        throw response ? response.data : error;
                        // return Promise.reject(error);
                    }
                } else {
                    let msg = error.message;
                    if (error.code == AxiosError.ERR_NETWORK)
                        msg = "API cannot be acessed. Maybe it's turned off? " + msg;
                    this.showErrorModal(msg);
                    throw error;
                    // return Promise.reject(error);
                }
            }
        );
        this.updateLoggedUser();
    }

    mounted() {
        this.systemService.version().then((systemVo) => {
            this.versao = systemVo.version;
        });
    }

    updateLoggedUser() {
        if (this.$route.fullPath != "/") this.$router.resolve("/");

        this.segService.loggedUser().then((value) => {
            if (value?.username && value.username.length > 1) {
                value.perfis = [];
                this.globalStore.setLoggedUser(value);
                this.logged = true;
                this.segService.listMyPermissions().then((perfis) => {
                    let logged = this.globalStore.loggedUser;
                    logged.perfis = perfis;
                    this.globalStore.setLoggedUser(logged);
                });
            }
        });
    }
}
