export class UrlsVO {
    dominio: string;
    main: string;
    api: string;

    constructor() {
        this.dominio = "adm.gomode.com.br";
        this.main = `https://${this.dominio}`;
        this.api = this.main + "/api";

        if (process.env.NODE_ENV != "production") {
            this.dominio = document.location.hostname;
            this.main = `http://${this.dominio}:8081`;
            this.api = `http://${this.dominio}:8080/gomode/api`;
            console.log(`-- Using ${this.api} as api URL`);
        }
    }
}
const URLS = new UrlsVO();

export default URLS;
