import { AxiosResponse } from "axios";
import { Routes } from "../../configs/router";
import { UserWithProfile } from "../AppDataStore";
import BaseCrudService from "../BaseCrudService";
import User, { Profile, UserPasswordChangeVO, UserPerson } from "./SecuyrityEntities";

export default class SecurityService extends BaseCrudService<User> {
    getBasePath(): string {
        return "user";
    }
    getSearchRoute(): string {
        return Routes.USERS;
    }
    getEditRoute(): string {
        return Routes.USER;
    }

    constructor() {
        super();
    }

    static get PERFIL_MARKETING() {
        return "Marketing Digital";
    }

    static get PERFIL_ALUNO() {
        return "Aluno";
    }

    static get PERFIL_INSTRUTOR() {
        return "Instructor";
    }

    static get PERFIL_ADM() {
        return "Administrator";
    }

    loggedUser(): Promise<UserWithProfile> {
        return this.http.get("loggedUser").then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    listarUsuariosAtivos() {
        return this.http.get(this.getBasePath()).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    listProfiles() {
        return this.http.get(`security/profile`).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    listMyPermissions(): Promise<Profile[]> {
        return this.http.get(`${this.getBasePath()}/my/profile`).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    listUserPermissions(username: string): Promise<Profile[]> {
        return this.http.get(`${this.getBasePath()}/${username}/profile`).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    logar(usuario: User): Promise<any> {
        return this.http
            .post(`login`, usuario, { withCredentials: true, headers: { "Access-Control-Allow-Origin": "*" } })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("Deu erro ao logar: " + err);

                throw err;
            });
    }

    logout() {
        return this.http
            .post(`logout`, {}, { withCredentials: true, headers: { "Access-Control-Allow-Origin": "*" } })
            .then((res: AxiosResponse) => {
                if (res && res.status >= 200 && res.status < 300) {
                    return res;
                }
            });
    }

    listaUsuarios() {
        return this.http.get("usuario/all").then((res: any) => {
            return this.handleRespose(res);
        });
    }

    obterUsuario(username: string): Promise<any> {
        return this.http.get(`${this.getBasePath()}/${username}`).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    obterUsuarioPorUserName(username: string) {
        return this.http.get(`${this.getBasePath()}/${username}`).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    obterUserPersonId(pessoaId: number) {
        return this.http.get(`${this.getBasePath()}/person/${pessoaId}`).then((res: any) => {
            return this.handleRespose(res);
        });
    }

    updateUserPermissions(userId: number, perfis: Profile[]) {
        return this.http.post(`/${this.getBasePath()}/${userId}/profile`, perfis).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    inserirUsuario(usuario: UserPerson) {
        return this.http.post(this.getBasePath(), usuario).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    inserirMeuUsuario(usuario: UserPerson) {
        return this.http
            .post(`${this.getBasePath()}/meu`, usuario)
            .then((res: any) => {
                return this.handleRespose(res);
            })
            .catch((e) => {
                throw e;
            });
    }

    criarMinhaSenha() {
        return this.http.get(`/security/password`).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    changeMyPassword(usuVo: UserPasswordChangeVO): Promise<any> {
        return this.http.post(`/security/user/changeMyPassword`, usuVo).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    associarChatTelegram(telegramCodigoAssociacao: string) {
        return this.http
            .post(`/security/bot/associar`, { telegramCodigoAssociacao: telegramCodigoAssociacao })
            .then((res: any) => {
                return this.handleRespose(res);
            });
    }

    updateUser(usuario: UserPerson) {
        return this.http.put(`${this.getBasePath()}`, usuario).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    obterMeusDados(): Promise<UserPerson> {
        return this.http.get(`${this.getBasePath()}/meusDados`).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }

    async generateToken(userId: number): Promise<string> {
        return await this.http.post(`${this.getBasePath()}/${userId}/token`).then((res: AxiosResponse) => {
            return this.handleRespose(res);
        });
    }
}
