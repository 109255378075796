// @ts-ignore
import moment from "moment";
// @ts-ignore
import toastr from "toastr";

export type ErrorsMap = { [key: string]: string[] };

export type FileUploadResponse = { fileName: string; ok: boolean; originalFileName: string }[];

export default class Utils {
    static exibirErro(msg: string) {
        toastr.error(msg);
    }

    static exibirMsg(msg: string) {
        toastr.success(msg);
    }

    /**
     * Parametros:
     *   image -> o conteúdo do "FileReader.target.result"
     *   tamanhoMaximo -> tamanho Maximo da largura ou altura
     *   arquivo -> objecto relativo ao input.file
     *   callback -> para enviar a figura redimensionada
     * Retorna:
     *   através do único parâmetro de "callback", conteúdo compatível com oparametro "image", mas redimensionado para o tamanho máximo
     */
    static redimensionarImagem(image: string, tamanhoMaximo: number, callback: (param: string) => any) {
        if (!tamanhoMaximo) {
            throw new Error("Obrigatório informar o tamanhoMaximo");
        }

        const img = document.createElement("img");
        img.src = image;
        img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            const MAX_WIDTH = tamanhoMaximo;
            const MAX_HEIGHT = tamanhoMaximo;

            // @ts-ignore
            let width = this.width;

            // @ts-ignore
            let height = this.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            callback(canvas.toDataURL("image/jpeg"));
        };
    }

    /**
     * Verifica o tamanho da imagem ([arquivo]) e, se for maior que [tamanho], redimensiona para que ou a altura ou a largura seja [dimensao], o que for maior.
     * Paramatros:
     *   - arquivo -> um arquivo vindo de um input.file.change
     *   - tamanho - tamanho máximo em bytes do arquivo
     *   - dimensao - dimensão máxima do arquivo, caso seja redimensionado
     *   - callback(valor) -> envia o arquivo (valor) em forma de urlData (FileReader.onload.evt.target.result)
     */
    static tratarTamanhoDeImagem(arquivo: any, tamanho: number, dimensao: number, callback: (param: string) => void) {
        const reader = new FileReader();
        reader.onload = function (e: any) {
            if (arquivo.size > tamanho) {
                Utils.exibirMsg(
                    "Tamanho máximo permitido do arquivo é " +
                        tamanho / 1000 +
                        "KB, sua foto será redimensionada para " +
                        dimensao +
                        "px de largura."
                );
                Utils.redimensionarImagem(e.target.result, dimensao, (result: string) => {
                    callback(result);
                });
            } else {
                callback(e.target.result);
            }
        }.bind(this);
        reader.readAsDataURL(arquivo);
    }

    /**
     * função para ser usada como callback do Array.prototype.sort(f(...)).
     * Considera-se que o dado da propriedade [nomeProp] seja uma data no formato [formatoData], ou
     * "YYYY-MM-DD HH:mm:ss", por padrão.
     */
    static ordenaObjPorData(
        objA: any,
        objB: any,
        nomeProp: string,
        formatoData: string,
        tipoOrdenacao: "ASC" | string
    ) {
        if (!formatoData) formatoData = "YYYY-MM-DD HH:mm:ss";
        if (!tipoOrdenacao) tipoOrdenacao = "ASC";

        const dataA = moment(objA[nomeProp], formatoData);
        const dataB = moment(objB[nomeProp], formatoData);

        if (tipoOrdenacao == "ASC") return dataA.diff(dataB);
        else return dataB.diff(dataA);
    }

    static extrairExtensao(nomeArquivo: string) {
        return nomeArquivo.substring(nomeArquivo.lastIndexOf(".") + 1);
    }

    /**
     *
     * @param obj1 Desconsidera o valor de objetos internos
     * @param obj2
     * @returns
     */
    static objEquals(obj1: any, obj2: any): boolean {
        if (obj1 == obj2) return true;
        else if ((!obj1 && obj2) || (obj1 && !obj2)) return false;
        else {
            const obj1Keys = Object.keys(obj1);
            if (obj1Keys.length != Object.keys(obj2).length) return false;
            else
                for (const key of obj1Keys) {
                    if (typeof obj1[key] == "object" || obj1[key] == obj2[key]) {
                        continue;
                    } else {
                        return false;
                    }
                }
            return true;
        }
    }

    /**
     * Retorna o valor da propriedade propPath presente no objeto obj.
     * Ex: getPropValueByPath({a: {b : "here"}}, "a.b") // retorna "here"
     * @param obj
     * @param propPath Ex: "pro1.prop2.prop3"
     */
    static getPropValueByPath(obj: any, propPath: string): any {
        const keys = propPath.split(".");
        let result = obj;
        for (const key of keys) {
            if (!result[key]) {
                result = null;
                // console.error(propPath + " não existe no objeto " + obj, obj);
                break;
            } else result = result[key];
        }
        return result;
    }

    static stringToColour(str: string) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xdd;
            colour += ("00" + value.toString(16)).substr(-2);
        }
        return colour;
    }
}

/**
 *
 * @param timeInSeconds number of seconds
 */
export function formatSeconds(timeInSeconds: number): string {
    return moment.utc(timeInSeconds * 1000).format("mm:ss.SSS");
}

export function formatSecondsOnly(timeInSeconds: number): string {
    return moment.utc(timeInSeconds * 1000).format("mm:ss");
}

/**
 * Must me used to calculate the css RGBA color that corresponds to given muysical intensity
 * @param intensity
 * @returns
 */
export function intensityColor(intensity: number): string {
    if (intensity == -1) return `rgba(113, 50, 229, 0.5)`;
    if (intensity == 0) return `rgba(100, 170, 255, 0.5)`;
    if (intensity == 10) return `rgba(255, 0, 0, 0.6)`;
    else return `rgba(255, ${255 - intensity * 20}, 0, 0.5)`;
}
