
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Info extends Vue {
    $props!: {
        icone?: string;
        iconeCor?: string;
        showNumber?: boolean;
    };

    @Prop({ type: String, required: false, default: "fa-info-circle" })
    icone?: string;

    @Prop({ type: String, required: false, default: "bg-info" })
    iconeCor?: string;

    @Prop({ type: Boolean, required: false, default: false })
    showNumber?: boolean;
}
