
import { Component } from "vue-property-decorator";
import SecurityService from "../../../domain/security/SecurityService";
import User from "../../../domain/security/SecuyrityEntities";
import BaseComponent from "../../BaseComponent";

@Component({ components: {} })
export default class LoginPAge extends BaseComponent {
    user = new User();
    segService = new SecurityService();

    logar() {
        this.segService.logar(this.user).then(() => {
            console.log("LOGGADO!!!!");
            this.$emit("logged", true);
        });
    }
}
