import AxiosHttp from "./AxiosHttp";
import BaseEntity from "./BaseEntity";

export default abstract class BaseCrudService<T extends BaseEntity> extends AxiosHttp {
    /**
     * PAra ser implementado retornanao o caminho base dessa entidade.
     */
    abstract getBasePath(): string;

    /**
     * De preferência, salvar em Routes.ts uma rota e usar.
     */
    abstract getSearchRoute(): string;

    abstract getEditRoute(): string;

    findById(id: number): Promise<T> {
        return this.http.get(`${this.getBasePath()}/${id}`).then((res) => {
            return this.handleRespose(res);
        });
    }

    /**
     *
     * @param searchTerm Texto a ser pesquisado na entidade.
     * @returns
     */
    findByText(searchTerm: string, hasPreview?: boolean): Promise<T[]> {
        return this.http.get(this.getBasePath(), { params: { searchTerm: searchTerm, hasPreview } }).then((res) => {
            return this.handleRespose(res);
        });
    }

    insert(entity: T): Promise<T> {
        return this.http.post(this.getBasePath(), entity).then((res) => {
            return this.handleRespose(res);
        });
    }

    update(entity: T) {
        return this.http.put(`${this.getBasePath()}/${entity.id}`, entity).then((res) => {
            return this.handleRespose(res);
        });
    }
}
