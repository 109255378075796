import { Component, Vue, Watch } from "vue-property-decorator";
import URLS, { UrlsVO } from "../domain/UrlsVO";

//@ts-ignore
import Modal from "../components/shared/modal/Modal.vue";

// @ts-ignore
import { Bus as VuedalsBus } from "vuedals";

import { NEW, OperacaoCrud } from "../domain/OperacaoCrud";
import { useGlobalStore } from "../domain/AppDataStore";
import { Routes } from "../configs/router";
import AxiosHttp from "../domain/AxiosHttp";
import Utils, { ErrorsMap } from "../domain/Utils";
import BaseEntity from "../domain/BaseEntity";
import BaseCrudService from "../domain/BaseCrudService";

export default class BaseComponent extends Vue {
    urls: UrlsVO = URLS;
    globalStore = useGlobalStore();
    Routes = Routes;

    get baseApiUrl() {
        return AxiosHttp.httpInstance.defaults.baseURL;
    }

    get $loggedUser() {
        return this.globalStore.loggedUser;
    }

    showAlertModal(msg: string) {
        this.showModal(msg, "warning");
    }

    showSuccessModal(msg: string) {
        this.showModal(msg, "success");
    }

    showErrorModal(msg: string) {
        this.showModal(msg, "error");
    }

    showModal(msg: string, type = "info") {
        let msgs: string[] = [];
        if (msg.indexOf("\n") > 0) {
            msgs = msg.split("\n");
        }

        VuedalsBus.$emit("new", {
            component: Modal,
            props: {
                msg: msg,
                type: type,
                msgs: msgs,
            },
        });
    }

    $possuiPerfil(perfis: any[]) {
        return this.$loggedUser.perfis.filter((perf) => perfis.includes(perf.name)).length > 0;
    }
}

/**
 * Ao implementar essa classe, não é obrigatório chamar o mounted:
 * <pre>
 * mounted() {
 *      super.mounted();
 * }</pre>
 */
@Component({})
export class BaseCrudComponent<ENT extends BaseEntity, SERV extends BaseCrudService<ENT>> extends BaseComponent {
    operacaoCrud: OperacaoCrud = OperacaoCrud.UPDATE;
    OperacaoCrud = OperacaoCrud;
    NEW = NEW;

    entity: ENT = {} as ENT;
    service: SERV;

    constructor() {
        super();
        this.service = this.createService();
        this.entity = this.createEntityInstance();
    }

    createService(): SERV {
        const msg = "createService() PRECISA SER SOBRESCRITO";
        Utils.exibirErro(msg);
        throw Error(msg);
    }

    createEntityInstance(): ENT {
        const msg = "createEntityInstance() PRECISA SER SOBRESCRITO";
        Utils.exibirErro(msg);
        throw Error(msg);
    }

    mounted() {
        this.entityIdChanged(this.$route.params.id);
        this.entity = this.createEntityInstance();
    }

    @Watch("$route.params.id")
    entityIdChanged(id?: string) {
        if (id == NEW) {
            this.entity = {} as ENT;
            this.operacaoCrud = OperacaoCrud.INSERT;
        } else {
            this.loadEntity();
        }
    }

    public loadEntity() {
        return this.service.findById(parseInt(this.$route.params.id)).then((entity) => {
            if (entity) {
                this.$set(this, "entity", Object.assign(this.createEntityInstance(), entity));
            } else {
                this.showAlertModal(
                    `The ${this.entity.getEntityLabel()} with id ${this.$route.params.id} does not exists`
                );
                this.$router.push(this.service.getSearchRoute());
            }

            this.afterLoad();
        });
    }

    afterLoad() {
        // To be overridden
    }

    async insert() {
        return this.service.insert(this.entity).then((entityInserted: ENT) => {
            console.log("Usuario inserido:", entityInserted);
            this.entity = Object.assign(this.createEntityInstance(), entityInserted);
            this.$nextTick(() => this.afterInsert());

            this.operacaoCrud = OperacaoCrud.UPDATE;
            this.showSuccessModal(`${this.entity.getEntityLabel()} inserted successfully.`);
            this.$router.replace(`${this.service.getEditRoute()}/${entityInserted.id}`);
        });
    }

    afterInsert() {
        //  to be overridden
    }

    update() {
        return this.service.update(this.entity).then((entityUpdated: ENT) => {
            Utils.exibirMsg(`${this.entity.getEntityLabel()} updated successfully`);
            this.entity = Object.assign(this.createEntityInstance(), entityUpdated);
            this.afterUpdate();
            return this.entity;
        });
    }

    afterUpdate() {
        // To be implemented by child
    }

    get inserting(): boolean {
        return this.operacaoCrud == OperacaoCrud.INSERT;
    }

    get updating(): boolean {
        return this.operacaoCrud == OperacaoCrud.UPDATE;
    }

    /**
     * result["valorDesconto"] = ["Obrigatório informar o percentual ou o valor."];
     */
    get errors(): ErrorsMap {
        const result: ErrorsMap = {};
        return result;
    }
}
