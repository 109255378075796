import BaseEntity from "../BaseEntity";

export default class User extends BaseEntity {
    constructor(
        id?: number,
        public username?: string,
        public password?: string,
        public ativo?: boolean,
        public nome?: string
    ) {
        super(id);
    }
    
    getEntityLabel(): string {
        return "User";
    }
}

export class UserPasswordChangeVO {
    constructor(public username?: string, public senhaAtual?: string, public novaSenha?: string) {}
}

export class UserPerson {
    constructor(public person: Person = new Person(), public user: User = new User()) {}
}

export class Person {
    constructor(public name?: string, public celular?: string) {}
}

export class Profile {
    constructor(public id?: number, public name?: string) {}
}
